/* Core styling */

:root {
    font-family: "#{$fa-font-family}" sans !important;
    font-size: 16px;

    scroll-behavior: smooth;
}

.no-caret {
    &::before {
        display: none;
    }

    &::after {
        display: none;
    }
}

.sample-text {
    font-size: 1.2rem;
    text-align: center;
    padding-top: 0;
    color-adjust: exact;
}

@mixin hideType($type) {
    :root.hide-#{$type} .type-#{$type} {
        display: none;
    }
}

@include hideType(normal);
@include hideType(deuteranopia);
@include hideType(protanopia);
@include hideType(tritanopia);
@include hideType(achromat);

.swatch-0 {
    min-height: 3.5rem;
}

.swatch-1 {
    min-height: 3.5rem;
}
